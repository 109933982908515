<template>
    <div class="header">
        <div class="menus">
            <RouterLink :to="menu.url" class="menuItem" v-for="(menu) in menuTab" :key="menu.url">{{ menu.label }}
            </RouterLink>
        </div>
        
       
        <!-- <div class="refresh" @click="refresh">
            <el-icon color="#5677fc">
                <Refresh />
            </el-icon>
        </div> -->
        <div class="userinfo">
            <el-dropdown split-button type="primary">
                <span class="user_name">{{ store.user_name }}</span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <RouterLink to="/user/userCenter"><el-dropdown-item class="item">修改密码</el-dropdown-item></RouterLink>
                        <el-dropdown-item class="exit item" @click="exit">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
    
</template>
<script setup>
import { useMainStore } from "/store/mainStore.js"
import { useRouter, useRoute } from "vue-router"
import { reactive,watch } from "vue";
import {useGetStoreList} from "@/use/store"
const route = useRoute();
const router = useRouter();
const store = useMainStore();
const menuTab = store.menuTab;
const {stores_option}=useGetStoreList();
const storeOption=reactive({
    placeholder:"请选择门店",
    options:stores_option,
    value:Number(store.curr_select_store_id)?Number(store.curr_select_store_id):""
})
watch(()=>storeOption.value,i=>{
    store.curr_select_store_id=i
    localStorage.setItem("curr_select_store_id",i)
})

const exit = () => {
    store.token = null;
    localStorage.clear("token");
    localStorage.clear("user_name")
}
const refresh = () => {

    router.replace({ path: "/xiarefresh", query: { path: route.path } })
}
</script>
<style lang="scss" scoped>
.header {
    background-color: var(--card-color);
    display: flex;
    color: #fff;
    height: 50px;
}
.storeOption{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 50px;
   
}
.refresh {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 50px;
    font-size: 28px;
    cursor: pointer;
    transition: all 300ms ease-in;

    &:hover {
        font-size: 35px;
        transform: rotateZ(360deg);
    }
}

.userinfo {
    min-width: 150px;
    max-width: 180px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;

    .user_name {
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
    }

    .item:hover {
        box-shadow: inset 2px 2px 10px #c6e2ff !important;
    }

    .exit {
        margin-left: 20px;
        font-size: 16px;
        color: antiquewhite;
        cursor: pointer;
    }
}

.menus {
    flex: 1;
    display: flex;
    overflow: hidden;

    .menuItem {
        flex-shrink: 0;
        margin-right: 2px;
        box-sizing: border-box;
        display: flex;
        padding: 0 15px;
        min-width: 100px;
        height: 50px;
        justify-content: center;
        align-items: center;
        color: #999;
        text-decoration: none;
        transition: all 500ms ease-in;
        
        &:hover {
            background-color: #c6e2ff;
        }
    }
}

.router-link-exact-active {
    background-color: var(--el-color-primary) !important;
    color: #fff !important;

}
</style>
<template>
    <div class="homepage">
        <div class="cardlist">
            <div class="card" v-if="store.user_type <= 2">
                <div>
                    <svg t="1680055021147" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="12576" width="60" height="60">
                        <path
                            d="M835.5 743.9c-1-2.5-2.1-4.9-3.2-7.4-13.3-29.5-50.6-38.9-76.3-19.2l-0.6 0.6c-17 17-21.7 42.6-12.3 64.7 13.1 30.7 20.3 64.4 20.3 99.8 0 1.5-1.2 2.7-2.7 2.7H254.6c-1.5 0-2.7-1.2-2.7-2.7 0-138.8 111.3-250.8 249.3-254.4h4c13.1 0 26-1 38.7-3 4.9-0.7 9.9-1.6 15-2.8 0.3-0.1 0.6-0.1 1-0.2 40.4-9 77.9-28 109.4-55.8 0.2-0.2 0.5-0.4 0.7-0.7 3.8-3.4 7.5-6.9 11.2-10.5 47-47 72.9-109.6 72.9-176.1s-25.9-129-72.9-176.1c-47-47-109.6-72.9-176.1-72.9s-129 25.9-176.1 72.9c-47 47-72.9 109.6-72.9 176.1S282 507.9 329 555c4.2 4.2 8.6 8.3 13.1 12.2-31.7 16.6-60.5 37.9-86.1 63.4-32.7 32.7-58.3 70.7-76.2 113.1-18.6 43.9-28 90.5-28 138.5 0 20.5 1.8 41.1 5.3 61.3 4.2 24 25 41.4 49.3 41.4h602.5c24.3 0 45.1-17.5 49.3-41.4 3.5-20.2 5.3-40.8 5.3-61.3 0-47.8-9.4-94.4-28-138.3z m-326-513.7c78.8 2.3 142.7 66.4 144.6 145.2C656 453 598.2 517.7 523.5 527c-5.3-0.2-10.5-0.4-15.8-0.4-6.5 0-13 0.2-19.4 0.5-74.2-8.4-132.1-71.6-132.1-148-0.1-83.6 69.1-151.4 153.3-148.9z"
                            fill="#80DEEA" opacity=".8" p-id="12577"></path>
                        <path d="M212.9 929.3m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12578"></path>
                        <path d="M416.9 573.9m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12579"></path>
                        <path d="M802.5 929.8m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12580"></path>
                    </svg>
                </div>
                <div>

                    <div class="num">
                        {{ homeData.userCount }}

                    </div>
                    <div class="cardtitle">
                        用户数量
                    </div>
                </div>
            </div>
            <div class="card">
                <div>
                    <svg t="1680054682987" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="6987" width="60" height="60">
                        <path d="M390.095238 755.809524h243.809524v146.285714H390.095238z" fill="#f4ea2a" p-id="6988">
                        </path>
                        <path
                            d="M731.428571 975.238095H292.571429c-26.819048 0-48.761905-21.942857-48.761905-48.761905s21.942857-48.761905 48.761905-48.761904h438.857142c26.819048 0 48.761905 21.942857 48.761905 48.761904s-21.942857 48.761905-48.761905 48.761905zM926.47619 780.190476H97.52381c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h828.95238c53.638095 0 97.52381 43.885714 97.52381 97.523809v536.380953c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                            fill="#f4ea2a" p-id="6989" data-spm-anchor-id="a313x.7781069.0.i20" class=""></path>
                        <path
                            d="M195.047619 572.952381c-14.628571-14.628571-14.628571-36.571429 0-51.2l185.295238-185.295238c14.628571-14.628571 36.571429-14.628571 51.2 0l131.657143 131.657143 212.114286-212.114286c14.628571-14.628571 36.571429-14.628571 51.2 0 14.628571 14.628571 14.628571 36.571429 0 51.2l-238.933334 238.933333c-14.628571 14.628571-36.571429 14.628571-51.2 0L407.161905 414.47619l-158.476191 158.476191c-17.066667 14.628571-39.009524 14.628571-53.638095 0z"
                            fill="#13227a" p-id="6990" data-spm-anchor-id="a313x.7781069.0.i21" class="selected"></path>
                    </svg>
                </div>
                <div>

                    <div class="num" style="color:#f00">
                        {{ homeData.totalRevenue }}￥
                    </div>
                    <div class="cardtitle">
                        门店总营收
                    </div>
                </div>
            </div>
           
           
           
          


            <div class="card" v-if="store.user_type <= 2">
                <div>
                    <svg t="1680054404255" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2819" width="60" height="60">
                        <path
                            d="M821.333333 256a32 32 0 0 1 64 0v565.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H256c-64.8 0-117.333333-52.533333-117.333333-117.333334V202.666667c0-64.8 52.533333-117.333333 117.333333-117.333334h597.333333a32 32 0 0 1 0 64H256a53.333333 53.333333 0 0 0-53.333333 53.333334v618.666666a53.333333 53.333333 0 0 0 53.333333 53.333334h512a53.333333 53.333333 0 0 0 53.333333-53.333334V256zM341.333333 437.333333a32 32 0 0 1 0-64h341.333334a32 32 0 0 1 0 64H341.333333z m0 170.666667a32 32 0 0 1 0-64h213.333334a32 32 0 0 1 0 64H341.333333z"
                            fill="#1296db" p-id="2820"></path>
                    </svg>
                </div>
                <div>

                    <div class="num">
                        {{ homeData.orderCount }}

                    </div>
                    <div class="cardtitle">
                        总订单
                    </div>
                </div>
            </div>
     


        </div>
       
      

    
       
      
      
    </div>
</template>
<script setup>
import * as echarts from 'echarts';
import { onMounted, ref, watch } from "vue";
import { useHome } from "@/use/home"
import { useMainStore } from "/store/mainStore.js"
const store = useMainStore();
const { homeData, monthIndex} = useHome();

let month=new Date().getMonth()+1







</script>
<style lang="scss" scoped>
.homepage {
    .cardlist {
        display: flex;
        width: 100%;
        margin-top: 10px;
        height: 110px;
        margin: auto;
        margin-left: 20px;
    }

    .card {
        flex: 1;
        margin-right: 20px;
        background-color: #fff;
        width: 350px;
        height: 100px;
        display: flex;
        border-radius: 5px;

        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
            width: 360px;
            height: 110px;
            box-shadow: 15px 15px 15px #aaa;
        }

        div {
            text-align: center;
        }

        .cardtitle {
            margin-bottom: 10px;
            font-size: 12px;
            color: var(--el-color-primary);
        }

        .num {

            margin: 10px;
            font-size: 30px;
        }
    }
}

.chartTitle {
    background-color: var(--el-color-primary);
    color: #fff;
    margin: 10px 20px;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
}

#chart,
#chart3,
#chart4,
#chart5,
#chart6,
#chart2 {
    flex: 1;
    height: 500px;
    border-radius: 5px;
    margin: 10px 20px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #eee;
    padding: 20px;
}

.flex {
    display: flex;
}
</style>
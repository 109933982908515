<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" @daochu="search(true)"></XIASearchBar>
        <div class="title">订单列表</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet" :height="750">
        <template #state="item">
            <el-tag  :class="'state' + item.row.state">
                    {{ getState(item.row.state) }}
                </el-tag >
           
        </template>
        <template #price="item">
            <el-tag :class="'state' + item.row.state">
              {{item.row.price}}      
            </el-tag >
           
        </template>
        <template #device_number="item">
            <el-tag >
              {{item.row.device_number}}      
            </el-tag >
           
        </template>
        <template #user_name="item">
            <el-tag >
              {{item.row.user_name}}      
            </el-tag >
           
        </template>
        <template #phone="item">
            <el-tag >
              {{item.row.phone}}      
            </el-tag >
           
        </template>
        <template #name="item">
            <el-tag >
              {{item.row.name}}      
            </el-tag >
           
        </template>
       
        <template #caozuo="item">
           
            
        </template>
        </TableComponent>


      
    </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useOrderIndex} from "@/use/order"
import {refunds} from "@/api/order"
import {ref} from "vue"
import { ElMessage } from 'element-plus';

const {searchData,headers,tableObjet,pay_review,search}=useOrderIndex()
const order=ref({})

const showTKModel=ref(false);

const pass=ref()
const amount=ref()
const getState=(i)=>{
    const states=['未支付','已支付',"已取消"]
    return states[i]
}
const tuikuan=()=>{

    if(!amount.value){
        ElMessage.error("请输入退款金额")
        return 
    }
    if(!pass.value){
        ElMessage.error("请输入操作密码")
        return 
    }

    refunds({order_num:order.value.order_num,amount:amount.value,pass:pass.value}).then(res=>{
        if(res.code==200){
            ElMessage.success(res.msg)
            showTKModel.value=false
        }else{
            ElMessage.error(res.msg)
        }
    })
}
</script>
<style lang="scss" scoped>
.state3 {
    color: #f00;
    font-weight: bold;
}

.state4 {
    color: #0c0;
    font-weight: bold;
}

.state2 {
    color: rgb(84, 84, 229);
    font-weight: bold;
}
.state5 {
    color: #999;
}
.dddd {
    .flex {
        align-items: center;
        height: 40px;

        span {
            margin-left: 35%;
            width: 100px;
        }
    }
}
</style>
<template>

    <div class="loginpage">
        <div class="content">
            <div  class="login-title">
                MIHOGO  ADMIN
            </div>
            
            <el-input placeholder="输入用户名" v-model="ruleForm.user_name" :class="user_name_error ? 'error' : ''"
                @click="user_name_error = false" />
            <el-input placeholder="输入密码" type="password" v-model="ruleForm.pass_word"
                :class="pass_word_error ? 'error' : ''"  @keydown="clickinput"/>
            <el-button type="primary" @click="userLogin">LOGIN</el-button>
        </div>
        <div class="bottom" >
           <a href="https://www.beian.gov.cn/portal/registerSystemInfo">

           </a> Copyright © 2010-2023 MIHOGO
        </div>
    </div>
</template>
<script setup>
import { reactive, ref, watchEffect } from 'vue';

import {useMainStore} from "/store/mainStore.js"
import { login } from "@/api/admin.js"
import { ElMessage } from "element-plus"

const store=useMainStore();

const user_name_error = ref(false)
const pass_word_error = ref(false);
const ruleForm = reactive({
    user_name: "",
    pass_word: ""
})
const clickinput=async (event)=>{
    pass_word_error.value = false
    if(event.key=="Enter"){
        await userLogin()
    }
}
const userLogin = async () => {
    if (!ruleForm.user_name) {
        user_name_error.value = true;
        return;
    }
    if (!ruleForm.pass_word) {
        pass_word_error.value = true;
        return;
    }
    const { msg, code, token, user_name,user_type,user_id } = await login(ruleForm);
    if (code == 200) {
        setTimeout(() => {
            localStorage.setItem("token",token);
            localStorage.setItem("user_name",user_name);
            localStorage.setItem("user_type",user_type);
            localStorage.setItem("user_id",user_id);
            store.token=token;
            store.user_id=user_id;
            store.user_type=user_type;
            store.user_name=user_name;
        }, 500);
        ElMessage({ type: 'success', message: msg });
    } else {
        ElMessage.error(msg);
    }
}
</script>

<style lang="scss" >
.bottom{
    position: fixed;
    bottom: 0;
    color: #fff;
}
.login-title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 25px;
}
.login-title2 {
    color: #ccc;
    margin-bottom: 30px;
    font-size: 18px;
}
.loginpage {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;


    z-index: 99;

    &::before {
        width: 100vw;
        height: 100vh;
        background-image: url("@/assets/bg.png");
        z-index: -1;
        content: "";
        filter: blur(2px);
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .content {

        text-align: center;
        display: flex;
        flex-direction: column;
        width: 350px;
       
        height: 50%;
        align-items: center;
        justify-content: center;
        padding: 35px;
        
        
        background-color: rgba($color: #05242b, $alpha: 0.3);
        border-radius: 5px;

        .el-input__wrapper {
            padding: 0;
            border-radius: 35px;
        }

        button {
            width: 150px;
            transition: all 500ms ease-in;
            margin-top: 30px;

            &:hover {
                width: 250px;
            }
        }

        .error {
            border: 2px solid #f00 !important;
        }

        .el-input {
            width: 150px;
            border-radius: 35px;
            margin: 15px;
            border: 0px;
            transition: all 500ms ease-in;

            input {
                height: 40px;
                border: 1px solid var(--el-color-primary);
                border-radius: 35px;
                text-align: center;
            }

            &:hover {
                width: 250px;

            }
        }
    }
}
</style>